






































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {BusinessDepartment} from '@/domain/model/types';

@Component({
  name: 'dialog-confirm',
  components: {BaseDialog}
})
export default class DialogConfirm extends Vue {
  // todo: add props for: width, height, max-width
  @Prop() value!: boolean
  @Prop() title!: string
  @Prop() text?: string
  @Prop() image?: any
  @Prop({default: ''}) textColor?: string
  @Prop({default: 'error'}) btnColor?: string
  @Prop({default: 'Ok'}) confirm?: string
  @Prop({default: true}) cancelShow?: boolean

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get srcImage() {
    const screenWindow: any = window
    const _URL = screenWindow.URL || screenWindow.webkitURL
    return _URL.createObjectURL(this.image)
  }

  get checkDefaultSlot() {
    return !!this.$slots.default
  }

  onCancel() {
    this.show = false
    this.$emit('on-cancel')
  }

  async onConfirm() {
    this.show = false
    this.$emit('on-confirm')
  }
}
